import React from 'react'
import img from '../../images/kriolipoliza-cialo.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Kriolipoliza = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Kriolipoliza | Modelowanie Sylwetki'
                    description='Kriolipoliza potocznie nazywana jest odchudzaniem przez zamrażanie.
          Jest to nowoczesna metoda redukcji tkanki tłuszczowej przy pomocy
          zimna, która pozwala na uzyskanie spektakularnych efektów w krótkim
          czasie.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/kriolipoliza'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Kriolipoliza</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='kriolipoliza zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Kriolipoliza potocznie nazywana jest odchudzaniem
                                        przez zamrażanie. Jest to nowoczesna metoda
                                        redukcji tkanki tłuszczowej przy pomocy zimna,
                                        która pozwala na uzyskanie spektakularnych efektów
                                        w krótkim czasie. Efekty są widoczne już po
                                        pierwszy zabiegu. To rewolucja w odchudzaniu,
                                        która zapewnia trwałą redukcję tkanki tłuszczowej
                                        bez użycia skalpela. Stanowi alternatywę do
                                        tradycyjnej, inwazyjnej liposukcji.
                                    </p>
                                    <p>
                                        Zabieg kriolipolizy polega na wymrażaniu tkanki
                                        tłuszczowej w temperaturze do -10 stopni przez
                                        min. 60 minut na danej partii ciała. Podczas
                                        zabiegu wykorzystuje się specjalną glicerynową
                                        membranę, która stanowi barierę pomiędzy głowicą
                                        urządzenia a miejscem poddanym zabiegowi.
                                        Chłodzenie jest odpowiednio kontrolowane i
                                        bezpieczne, a wymrażanie komórek następuje
                                        selektywnie zapewniając wysoką skuteczność.
                                        Uszkodzone komórki tłuszczowe zostają uwolnione i
                                        usuwane z organizmu poprzez układ limfatyczny w
                                        naturalnych procesach fizjologicznych. Pojedynczy
                                        zabieg trwa 60 minut. Na daną partię ciała można
                                        wykonać maksymalnie 3 zabiegi, każdy w odstępie
                                        6-8 tygodni. Ilość zabiegów dobierana jest
                                        indywidualnie w zależności od rozmiaru pola
                                        zabiegowego oraz efektu jaki chcemy osiągnąć.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>KRIOLIPOLIZA</h2>
                                        <ul>
                                            <li>
                                                <p>KRIOLIPOLIZA - 1 PRZYŁOŻENIE</p>
                                                <span className='prices'>
                                                    60 MIN | 450 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>KRIOLIPOLIZA - 2 PRZYŁOŻENIE</p>
                                                <span className='prices'>
                                                    60 MIN | 650 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>KRIOLIPOLIZA - 3 PRZYŁOŻENIE</p>
                                                <span className='prices'>
                                                    60 MIN | 800 zł
                                                </span>
                                            </li>
                                        </ul>
                                        <h2>KRIOLIPOLIZA - BOCZKI, UDA</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    KRIOLIPOLIZA - BOCZKI, UDA - 1
                                                    PRZYŁOŻENIE
                                                </p>
                                                <span className='prices'>
                                                    120 MIN | 550 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    KRIOLIPOLIZA - BOCZKI, UDA - 2
                                                    PRZYŁOŻENIE
                                                </p>
                                                <span className='prices'>
                                                    120 MIN | 800 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    KRIOLIPOLIZA - BOCZKI, UDA - 3
                                                    PRZYŁOŻENIE
                                                </p>
                                                <span className='prices'>
                                                    120 MIN | 900 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/beautiful-girl-is-engaged-yoga-studio_6423759.htm'>
                    Image by prostooleh
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default Kriolipoliza
